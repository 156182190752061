// This file is generated automatically by `useTemplate.ts`. DO NOT EDIT IT.

import { createElement,forwardRef, Ref } from 'react';
import { IconBase, IconProps } from '../icon';

const element = {"tag":"svg","attrs":{"fill":"none","viewBox":"0 0 24 24","width":"1em","height":"1em"},"children":[{"tag":"path","attrs":{"fill":"currentColor","d":"M10.88 23.02l-9.9-9.9L11.1 3.02 21 3v9.91L10.88 23.02zm0-2.83L19 12.1V5l-7.07.01-8.12 8.11 7.07 7.07zM15 9V7h2v2h-2z"}}]};

const TagIcon = forwardRef<SVGElement, IconProps>((props: IconProps, ref: Ref<SVGElement>) => createElement(
  IconBase,
  {
    ...props,
    id: 'tag',
    ref,
    icon: element,
  },
));

TagIcon.displayName = 'TagIcon';

export default TagIcon;
